<template>
  <Modal v-model="showModal">
    <div class="bg-blue px-4 py-10 text-center text-white">
      <h2 class="font-bw-gradual mb-4 text-2rem font-bold">
        Sign up for Tripexpert
      </h2>
      <p class="text-xl">
        To save lists and access other<br> interactive features, sign up or log in.
      </p>
    </div>
    <div class="bg-white pt-4">
      <div v-if="loading" class="mb-10 h-20 spinner" />
      <div id="firebaseui-auth-container" />
    </div>
  </Modal>
</template>

<script>
import { initFirebase } from '~/utils/firebase'
import Modal from '~/components/shared/Modal'
import { useAuthStore } from '~/stores/auth'

export default {
  components: { Modal },

  data: () => ({
    loading: false,
    showModal: false,
  }),

  mounted () {
    this.$eventHub.on('show-auth', () => {
      this.init()
    })
  },

  methods: {
    async init () {
      this.loading = true
      const [, , auth] = await initFirebase(this.$store)
      if (useAuthStore().user) {
        this.loading = false
        return
      }

      this.showModal = true
      const firebaseui = await import('firebaseui')
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth.getAuth())

      const config = {
        credentialHelper: firebaseui.auth.CredentialHelper.NONE,
        signInOptions: [
          auth.GoogleAuthProvider.PROVIDER_ID,
          auth.EmailAuthProvider.PROVIDER_ID,
          auth.FacebookAuthProvider.PROVIDER_ID,
          'apple.com',
        ],

        signInFlow: 'popup',
        tosUrl: '/tos',
        privacyPolicyUrl: '/privacy-policy',
        callbacks: {
          signInSuccessWithAuthResult: this.signInResult,
        },
      }
      ui.disableAutoSignIn()
      if (useAuthStore().user) {
        this.signedIn()
      } else {
        ui.start('#firebaseui-auth-container', config)
      }
      this.loading = false
    },

    signInResult () {
      this.signedIn()
      return false
    },

    signedIn () {
      this.showModal = false
    },
  },
}
</script>

<style src="~/node_modules/firebaseui/dist/firebaseui.css"></style>

<style lang="scss" scoped>
:deep() {
  .modal-close-button path { fill: #fff !important; }
}
</style>
