<template>
  <div class="overflow-x-hidden">
    <slot />
    <ClientOnly>
      <AuthModal />
    </ClientOnly>
  </div>
</template>

<script>
import { useRoute, useRuntimeConfig } from 'nuxt/app'
import AuthModal from '~/components/shared/AuthModal'
import { useHead } from '#imports'

export default {
  components: { AuthModal },

  setup () {
    useHead({
      link: [
        { hid: 'canonical', rel: 'canonical', href: useRuntimeConfig().public.appHost + useRoute().path },
      ],
    })
  },
}
</script>
